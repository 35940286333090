import styled from 'styled-components'
import { regularFontFamily } from '../variables'

const LabelStyle = styled.div`
  margin-bottom: 5px;
  color: black;
  font-weight: bold;
  font-family: ${regularFontFamily};
  font-size: 14px;
  line-height: 1.42857143;
`

const RequireStyle = styled.span`
  font-family: ${regularFontFamily};
  font-weight: 300;
  font-style: italic;
  font-size: 14px;
  color: #696977;
`

const LabelWrapper = styled.label`
  width: 100%;
`

interface FormGroupWrapperProps {
  hasError?: string
}

const FormGroupWrapper = styled.div<FormGroupWrapperProps>`
  margin-bottom: 5px;
  display: flex;
  flex-flow: column;
  input {
    border: ${(props) => props.hasError && '1.5px solid #de4848'};
  }
`

const FormGroupChildren = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`

const TextDanger = styled.div`
  color: #b92c22;
  margin-top: 10px;
  font-size: 14px;
  font-family: ${regularFontFamily};
  line-height: 1.42857143;
`

export {
  TextDanger,
  FormGroupChildren,
  FormGroupWrapper,
  LabelWrapper,
  RequireStyle,
  LabelStyle,
}
