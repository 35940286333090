import style from 'styled-components'
import { janeWhite } from '../variables'

interface PanelWrapperProps {
  borderBottomNone?: boolean
  marginTopNone?: Boolean
  removeBorderRadius?: 'top' | 'bottom'
}

const removeBorderRadius = (borderRadiusProp: 'top' | 'bottom' | undefined) => {
  switch (borderRadiusProp) {
    case 'top':
      return '0px 0px 4px 4px'
    case 'bottom':
      return '4px 4px 0px 0px'
    default:
      return '4px 4px 4px 4px'
  }
}

const PanelWrapper = style.div<PanelWrapperProps>`
  box-shadow: none;
  border-color: #ddd;
  background-color: ${janeWhite};
  border: 1px solid #DDDDDD;
  border-radius: ${(props) => removeBorderRadius(props.removeBorderRadius)};
  ${(props) =>
    props.borderBottomNone &&
    `
    border-bottom: 0px;
  `}
  margin-top: ${(props) => (props.marginTopNone ? '0' : '1.5rem')};
  padding: 1rem;
  position: relative;
`

const PanelBody = style.div`
  padding: 0px;
`

export { PanelWrapper, PanelBody }
