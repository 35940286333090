/* Colors */
const primary = '#00C1CA'
const secondary = '#fba919'
const janeWhite = '#fff'
const janeDark = '#333333'
const textDark = '#696977'
const activePrimaryBorder = '#006e73'
const lightGrey = '#F5F5F5'
const linkDark = '#237278'

/* Font */
const regularFontFamily =
  '"ProximaNovaRgRegular", "Helvetica Neue", Helvetica, Arial, sans-serif'
const thinFontFamily =
  '"ProximaNovaThThin", "Helvetica Neue", Helvetica, Arial, sans-serif'
const fontRegular = '14px'
const fontWeight = '400'

/* Logo URL */
const logoUrl =
  'https://jane.app/assets/jane-header-logo-484508ce615be419a05d8f5a30e4cf47.svg'

export {
  primary,
  secondary,
  janeWhite,
  janeDark,
  regularFontFamily,
  thinFontFamily,
  logoUrl,
  activePrimaryBorder,
  fontRegular,
  fontWeight,
  textDark,
  lightGrey,
  linkDark,
}
