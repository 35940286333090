import { PanelWrapper } from './Panel.style'

interface PanelType {
  children?: React.ReactNode
  className?: string
  borderBottomNone?: boolean
  marginTopNone?: boolean
  removeBorderRadius?: 'top' | 'bottom'
}

const Panel: React.FC<PanelType> = ({
  children,
  className,
  borderBottomNone,
  marginTopNone,
  removeBorderRadius,
}) => {
  return (
    <PanelWrapper
      className={className}
      borderBottomNone={borderBottomNone}
      marginTopNone={marginTopNone}
      removeBorderRadius={removeBorderRadius}
    >
      {children}
    </PanelWrapper>
  )
}

export { Panel }
