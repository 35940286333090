import DefaultLogo from "../../assets/logoDefault.png"
import { ClinicType } from "../../interfaces/LoginSecurity"
import { SearchResultClinicButton } from "./SearchResultClinicButton"

interface CinicPanelDesktopProps {
  className?: string
  addClinicUrl: string
  clinic: ClinicType
  title: string
  subtitle: string
  logo: string
}

interface ImageErrorEvent
  extends React.SyntheticEvent<HTMLImageElement, Event> {
  target: HTMLImageElement
}

export const ClinicPanelDesktop = ({
  className,
  addClinicUrl,
  clinic,
  title,
  subtitle,
  logo,
}: CinicPanelDesktopProps) => {
  return (
    <div
      data-testid="clinic-result"
      className={`p-4 rounded border-[1px] border-greyLighter flex flex-col ${className}`}
    >
      <div className="flex items-center">
        {logo && (
          <div className="h-10 w-10 mr-2 border-[1px] border-greyLighter rounded flex justify-center items-center">
            <img
              className="rounded"
              src={logo}
              alt={`${title} logo`}
              onError={(e: ImageErrorEvent) => {
                e.target.src = DefaultLogo
              }}
            />
          </div>
        )}

        <div className="flex flex-col">
          <div className="font-bold	text-base">{title}</div>
          <div className="font-light text-sm">{subtitle}</div>
        </div>
      </div>
      <SearchResultClinicButton
        isLinked={clinic.linked}
        addClinicUrl={addClinicUrl}
      />
    </div>
  )
}
