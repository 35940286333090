import { useEffect, useMemo, useRef } from "react"
import { debounce } from "underscore"

// From: https://www.developerway.com/posts/debouncing-in-react
// @ts-ignore
export const useDebounce = (callback, delay) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = (...params: any) => {
      // @ts-ignore
      ref.current?.(...params)
    }

    return debounce(func, delay)
  }, [])

  return debouncedCallback
}
