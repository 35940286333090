import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dispatch, SetStateAction, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import DefaultLogo from "../../assets/logoDefault.png"
import { Panel } from "../../components"
import { ReactComponent as Spinner } from "../../images/Spinner.svg"

interface ImageErrorEvent
  extends React.SyntheticEvent<HTMLImageElement, Event> {
  target: HTMLImageElement
}

const LoginToClinic = ({
  url,
  setIsLoading,
}: {
  url: string
  setIsLoading: Dispatch<SetStateAction<boolean>>
}) => {
  const janeSsoLoginLink = `${url}/jane_sso/login`
  const handleRedirectToClinic = () => {
    setIsLoading(true)
    window.location.href = janeSsoLoginLink
  }
  return (
    <button
      className="p-4 border border-greyLighter rounded my-4 leading-[14px]"
      onClick={handleRedirectToClinic}
    >
      Go to My Profile
    </button>
  )
}

const RemoveJaneIDButton = ({
  setShowModal,
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <div className="flex justify-center">
      <button onClick={() => setShowModal(true)} className="text-greyDark mt-6">
        Remove from my Jane ID
      </button>
    </div>
  )
}

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen fixed w-screen top-0 left-0 bg-white">
    <Spinner className="h-20 w-20" />
  </div>
)

const UnLinkClinicModal = ({
  name,
  url,
  setShowModal,
  setIsLoading,
}: {
  name: string
  url: string
  setShowModal: Dispatch<SetStateAction<boolean>>
  setIsLoading: Dispatch<SetStateAction<boolean>>
}) => {
  const UnlinkClinicUrl = `${url}/jane_sso/unlink/dashboard`

  return (
    <div className=" fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="rounded shadow-modalShadow p-4 bg-white fixed top-1/3 sm:top-1/4 mx-3.5">
        <h1 className="text-2xl text-left sm:text-3xl font-light leading-9 text-greyDark">
          Are you sure?
        </h1>
        <p className="text-sm text-greyDark leading-5 border-t border-greyLighter mt-5 sm:mt-6 pt-6">
          Are you sure you want to remove {name} from your Jane ID?
        </p>
        <div className="border-t border-greyLighter mt-6 pt-6 flex justify-end">
          <button
            onClick={() => setShowModal(false)}
            className="p-[10px] border border-greyLighter rounded leading-[14px] text-sm"
          >
            Cancel
          </button>
          <button
            className="bg-janeRed text-white text-sm p-[10px] leading-[14px] rounded border-greyLighter ml-4 text-left"
            onClick={() => {
              setIsLoading(true)
              window.location.href = UnlinkClinicUrl
            }}
          >
            Yes, remove from Jane ID
          </button>
        </div>
      </div>
    </div>
  )
}

export const Clinic = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const linkLocation = useLocation()
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  const { name, lookup_address, logo_url, url } = linkLocation.state?.data

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <button className="text-janeBlue" onClick={goBack}>
            <FontAwesomeIcon className="mr-[0.438rem]" icon={faArrowLeft} />
            <span className="font-semibold">Back</span>
          </button>
          <div className="text-2xl text-janeBlack mt-2 font-semibold">
            {name}
          </div>
          <Panel className="mb-0 flex flex-col items-center justify-center">
            <div className="flex flex-col items-center">
              {logo_url && (
                <img
                  alt={`${name} Logo`}
                  src={logo_url}
                  className="w-20 h-20 object-contain"
                  onError={(e: ImageErrorEvent) => {
                    e.target.src = DefaultLogo
                  }}
                />
              )}
              <div className="text-base text-janeBlack font-semibold text-center">
                {name}
              </div>
              <div className="text-sm text-greyDark text-center">
                {lookup_address}
              </div>
            </div>
            <LoginToClinic url={url} setIsLoading={setIsLoading} />
          </Panel>
          <RemoveJaneIDButton setShowModal={setShowModal} />
          {showModal && (
            <UnLinkClinicModal
              name={name}
              url={url}
              setShowModal={setShowModal}
              setIsLoading={setIsLoading}
            />
          )}
        </div>
      )}
    </>
  )
}
