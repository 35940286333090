import styled from 'styled-components'
import { primary, regularFontFamily } from '../variables'

interface LinkWrapperProps {
  bold?: boolean
  href?: string
}

const LinkWrapper = styled.a<LinkWrapperProps>`
  color: ${primary};
  text-decoration: none;
  font-weight: ${(props) => props.bold && 'bold'};
`
const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0;
  label {
    margin-left: 1rem;
    font-family: ${regularFontFamily};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`
export { CheckBoxWrapper, LinkWrapper }
