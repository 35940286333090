import React, { useEffect, useState } from "react"
import { DeleteSessionByDeviceId, GetInitialDataCalls } from "../helpers/api"
import {
  ClinicListType,
  DeviceSessionWithOS,
  ErrorAPICalls,
  KCSessionDevicesResponseType,
  KeycloakContextType,
  UserCredentialsType,
} from "../interfaces/LoginSecurity"

export const KeycloakContext = React.createContext<KeycloakContextType>({
  clinics: [],
  userCredentials: null,
  connectedDevices: [],
  signOutSessionByDeviceId: () => null,
  loading: false,
  error: {},
})

interface KeycloakContextProviderType {
  children: React.ReactNode
}

const KeycloakContextProvider = ({ children }: KeycloakContextProviderType) => {
  const [clinics, setClinics] = useState<ClinicListType>([])
  const [userCredentials, setUserCredentials] = useState<UserCredentialsType>({
    id: 0,
    username: "",
    firstName: "",
    lastName: "",
    email: "",
  })
  const [connectedDevices, setConnectedDevices] = useState<
    DeviceSessionWithOS[]
  >([])

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorAPICalls>({
    clinicError: false,
    userCredentialError: false,
    connectedDevicesError: false,
  })

  const listOfSessionsWithDevices = (
    devices: KCSessionDevicesResponseType[]
  ) => {
    const currentSessions: DeviceSessionWithOS[] = []
    const nonCurrentSessions: DeviceSessionWithOS[] = []
    devices.forEach((device: KCSessionDevicesResponseType) => {
      device.sessions.forEach((session: any) => {
        const updatedSession: DeviceSessionWithOS = {
          ...session,
          os: device.os,
        }
        if (session.current) {
          currentSessions.push(updatedSession)
        } else {
          nonCurrentSessions.push(updatedSession)
        }
      })
    })
    return [...currentSessions, ...nonCurrentSessions]
  }

  const signOutSessionByDeviceId = async (deviceId: string, idx: number) => {
    await DeleteSessionByDeviceId(deviceId)
    const currentDeviceList = [...connectedDevices]
    currentDeviceList.splice(idx, 1)
    setConnectedDevices(currentDeviceList)
  }

  const handleAccountResponse = (accountInfo: any) => {
    if (accountInfo.status === "fulfilled") {
      return setUserCredentials({
        email: accountInfo?.value?.email,
      })
    } else {
      setError((prevState) => ({
        ...prevState,
        userCredentialError: true,
      }))
    }
  }

  const handleDevicesReponse = (
    deviceInfo: PromiseSettledResult<KCSessionDevicesResponseType[]>
  ) => {
    if (deviceInfo.status === "fulfilled") {
      const sessions: DeviceSessionWithOS[] = listOfSessionsWithDevices(
        deviceInfo.value
      )
      setConnectedDevices(sessions)
    } else {
      setError((prevState) => ({
        ...prevState,
        connectedDevicesError: true,
      }))
    }
  }

  const handleClinicResponse = (
    clinicInfo: PromiseSettledResult<{ data: ClinicListType }>
  ) => {
    if (clinicInfo.status === "fulfilled") {
      const clinics: ClinicListType = clinicInfo.value["data"] ?? []
      setClinics(clinics)
    } else {
      setError((prevState) => ({
        ...prevState,
        clinicError: true,
      }))
    }
  }

  useEffect(() => {
    const setDataFromInitialDataCall = async () => {
      setLoading(true)
      try {
        const response: {
          account: PromiseSettledResult<UserCredentialsType>
          devices: PromiseSettledResult<KCSessionDevicesResponseType[]>
          clinics: PromiseSettledResult<{ data: ClinicListType }>
        } = await GetInitialDataCalls()

        if (response) {
          const { account, devices, clinics } = response
          handleClinicResponse(clinics)
          handleAccountResponse(account)
          handleDevicesReponse(devices)
        }
      } catch (error: any) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    setDataFromInitialDataCall()
  }, [])

  return (
    <KeycloakContext.Provider
      value={{
        clinics,
        userCredentials,
        connectedDevices,
        signOutSessionByDeviceId,
        loading,
        error,
      }}
    >
      {children}
    </KeycloakContext.Provider>
  )
}

export default KeycloakContextProvider
