import { ButtonStyle } from "./Button.style"

export interface ButtonProps {
  variant?: "primary" | "link" | "white-link"
  xs?: boolean
  sm?: boolean
  lg?: boolean
  block?: boolean
  lightWeight?: boolean
  noPadding?: boolean
  disabled?: boolean
  children?: React.ReactNode
  type?: "button" | "submit" | "reset"
  fontFamily?: "regular" | "thin"
  onClick?: () => void
}
export const Button = (props: ButtonProps) => {
  const { ...rest } = props
  return <ButtonStyle {...rest} />
}
