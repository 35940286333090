import style from 'styled-components'
import { janeDark, thinFontFamily } from '../variables'

const HeadingXL = style.h1`
  letter-spacing: -1px;
  font-weight: 200;
  letter-spacing: 1px;
  font-family: ${thinFontFamily};
  color: ${janeDark};
  text-align: center;
  margin-top: 1rem;
`

export { HeadingXL }
