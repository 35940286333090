import { ClinicType } from "../../interfaces/LoginSecurity"
import { ClinicPanelDesktop } from "./ClinicPanelDesktop"
import { ClinicPanelMobile } from "./ClinicPanelMobile"

interface SearchResultsProp {
  clinicResults: ClinicType[]
  isMobile: boolean
  className?: string
}

export const SearchResults = ({
  clinicResults,
  isMobile,
  className,
}: SearchResultsProp) => {
  const clinics = clinicResults.map((clinic, index) => {
    let addClinicUrl = `${clinic.url}/jane_sso/link/dashboard`
    if (isMobile) {
      addClinicUrl = `${clinic.url}/jane_sso/link/mobile`
    }

    return (
      <div className="mt-2">
        {isMobile ? (
          <ClinicPanelMobile
            className={className}
            key={clinic.url}
            addClinicUrl={addClinicUrl}
            clinic={clinic}
            title={clinic.name}
            subtitle={clinic.lookup_address}
            logo={clinic.logo_thumbnail_url}
          />
        ) : (
          <ClinicPanelDesktop
            className={className}
            key={clinic.url}
            addClinicUrl={addClinicUrl}
            clinic={clinic}
            title={clinic.name}
            subtitle={clinic.lookup_address}
            logo={clinic.logo_thumbnail_url}
          />
        )}
      </div>
    )
  })
  return <>{clinics}</>
}
