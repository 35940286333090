import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Search } from "../../components/Search"
import { QueryClinics } from "../../helpers/api"
import { useDebounce } from "../../helpers/hooks/useDebounce"
import { SearchClinicError } from "./SearchClinicError"
import { SearchResults } from "./SearchResults"

const SearchClinic = () => {
  const [clinicResults, setClinicResults] = useState([])
  const [isError, setErrorState] = useState<Boolean>(false)
  const [params] = useSearchParams()
  const isMobile = params.get("device") === "mobile"

  const handleSearch = async (value: string) => {
    try {
      const response = await QueryClinics(value)
      const responseData = await response.data
      setClinicResults(responseData)
    } catch (e) {
      setErrorState(true)
      setClinicResults([])
    }
  }

  const searchDebounced = useDebounce(handleSearch, 500)

  return (
    <>
      <Search handleSearchText={(val) => searchDebounced(val)} />
      {!isError ? (
        clinicResults.length > 0 && (
          <SearchResults clinicResults={clinicResults} isMobile={isMobile} />
        )
      ) : (
        <SearchClinicError />
      )}
    </>
  )
}

export { SearchClinic }
