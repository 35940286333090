import React, { ReactNode } from "react"

type ErrorBoundaryProps = {
  fallback: ReactNode
  children: ReactNode
  header?: ReactNode
  displayName?: string
}

type ErrorBoundaryState = {
  hasError: boolean
  displayName?: string
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          {this.props.header}
          {this.props.fallback}
        </>
      )
    }

    return this.props.children
  }
}

;(ErrorBoundary as any).displayName = "ErrorBoundary"

export default ErrorBoundary
