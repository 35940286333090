import emojiFlags from 'emoji-flags'
import { getCountryCallingCode } from 'libphonenumber-js'
import { Dispatch, SetStateAction } from 'react'
import { sortBy } from 'underscore'
import { Select } from '../Select'
import { CountryWrapper } from './Country.style'

const countries = sortBy(emojiFlags.data, (c) => c.name)

interface CountryInputProps {
  defaultCountry?: string
  emoji?: boolean
  onChange: Dispatch<SetStateAction<string>>
  id?: string
  name?: string
}

export const Country = (props: CountryInputProps) => {
  const options = countries.map((c: any) => {
    let phone_country_code

    try {
      phone_country_code = getCountryCallingCode(c.code)
    } catch (error) {
      // let pass if error getting country code
    }
    let label = c.name

    if (props.emoji) {
      label = `${c.emoji} ${label}`
    }

    return { value: `+${phone_country_code}`, label }
  })

  return (
    <CountryWrapper>
      <Select
        options={options}
        defaultValue={props.defaultCountry}
        {...props}
      />
    </CountryWrapper>
  )
}
