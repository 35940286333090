import { env } from "../env"
import {
  ClinicListType,
  KCSessionDevicesResponseType,
  UserCredentialsType,
} from "../interfaces/LoginSecurity"
import {
  USER_ACCOUNT_URL,
  USER_DEVICES_URL,
  USER_LINKED_CLINICS_URL,
} from "./constants"

export const GetInitialDataCalls = async (): Promise<{
  account: PromiseSettledResult<UserCredentialsType>
  devices: PromiseSettledResult<KCSessionDevicesResponseType[]>
  clinics: PromiseSettledResult<{ data: ClinicListType }>
}> => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }

  const urls: string[] = [
    USER_ACCOUNT_URL,
    USER_DEVICES_URL,
    USER_LINKED_CLINICS_URL,
  ]

  const accountDataRequests = urls.map((url) => {
    return fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw new Error(`${response.status} ${response.statusText}`)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  })

  const accountDataResults = await Promise.allSettled(accountDataRequests)
  const accountDataCollectedResults = await {
    account: accountDataResults[0],
    devices: accountDataResults[1],
    clinics: accountDataResults[2],
  }

  return accountDataCollectedResults
}

export const DeleteSessionByDeviceId = (deviceId: string) => {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  }
  return fetch(
    `${env.REACT_APP_KEYCLOAK_BASE_URL}/account/sessions/${deviceId}`,
    options
  )
    .then((res) => {
      console.log(res)
      return res
    })
    .catch((err) => console.log(err))
}

export const DeleteAllSessions = () => {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  }
  return fetch(`${env.REACT_APP_KEYCLOAK_BASE_URL}/account/sessions`, options)
    .then((res) => {
      return res
    })
    .catch((err) => console.log(err))
}

export const LinkedClinics = () => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }
  return fetch(USER_LINKED_CLINICS_URL, options)
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw new Error(`${response.status} ${response.statusText}`)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const QueryClinics = (query: string) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }
  return fetch(`${env.REACT_APP_ID_API_BASE_URL}/clinics?q=${query}`, options)
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
      throw new Error(`${res.status} ${res.statusText}`)
    })
    .catch((err) => console.log(err))
}
