import { OidcProvider, OidcSecure } from "@axa-fr/react-oidc"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { configuration } from "./configuration"
import KeycloakContextProvider from "./context/KeycloakContext"
import { env } from "./env"
import { ReactComponent as Spinner } from "./images/Spinner.svg"
import "./index.css"
import { Clinic } from "./pages/Clinic"
import ErrorBoundary from "./pages/ErrorBoundary"
import { GenericErrorPage } from "./pages/GenericErrorPage"
import { Home } from "./pages/Home"
import { Layout } from "./pages/Layout"
import { SearchClinic } from "./pages/SearchClinic"
import { initializeSentry } from "./sentry"

const LoadingScreen = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <Spinner className="h-20 w-20" />
    </div>
  )
}
initializeSentry()

function App() {
  return (
    <OidcProvider
      configuration={configuration}
      loadingComponent={LoadingScreen}
      authenticatingComponent={LoadingScreen}
      authenticatingErrorComponent={GenericErrorPage}
      callbackSuccessComponent={() => <></>}
      onSessionLost={() =>
        window.location.replace(`${env.REACT_APP_DASHBOARD_BASE_URL}`)
      }
    >
      <OidcSecure>
        <ErrorBoundary fallback={<GenericErrorPage />}>
          <KeycloakContextProvider>
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={<Layout />}
                  errorElement={<GenericErrorPage />}
                >
                  <Route index element={<Home />} />
                  <Route path="clinics/:id" element={<Clinic />} />
                  <Route path="search-clinic" element={<SearchClinic />} />
                </Route>
                <Route path="*" element={<GenericErrorPage />} />
              </Routes>
            </BrowserRouter>
          </KeycloakContextProvider>
        </ErrorBoundary>
      </OidcSecure>
    </OidcProvider>
  )
}

export default App
