declare global {
  interface Window {
    env: any
  }
}

// change with your own variables
type EnvType = {
  REACT_APP_KEYCLOAK_BASE_URL: string
  REACT_APP_ID_API_BASE_URL: string
  REACT_APP_DASHBOARD_BASE_URL: string
}

export const env: EnvType = { ...process.env, ...window["env"] }
