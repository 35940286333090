import styled from "styled-components"
import {
  activePrimaryBorder,
  janeWhite,
  primary,
  regularFontFamily,
  thinFontFamily,
} from "../variables"
import { ButtonProps } from "./index"

const paddingBaseVertical = "6px"
const paddingBaseHorizontal = "12px"

const paddinglargeVertical = "10px"
const paddinglargeHorizontal = "16px"

const paddingsmallVertical = "5px"
const paddingsmallHorizontal = "10px"

const paddingxsVertical = "1px"
const paddingxsHorizontal = "5px"

const lineHeightLarge = 1.3333333 // extra decimals for Win 8.1 Chrome
const lineHeightSmall = 1.5
const lineHeightBase = 1.428571429

const borderRadiusBase = "4px"
const borderRadiusLarge = "6px"
const borderRadiusSmall = "3px"

const fontSizeLarge = "18px"
const fontSizeLink = "16px"
const fontSizeBase = "14px"
const fontSizeSmall = "12px"

export const ButtonStyle = styled.button<ButtonProps>`
  font-style: normal;
  font-family: ${(props) =>
    props.fontFamily === "regular"
      ? regularFontFamily
      : props.fontFamily === "thin" && thinFontFamily};
  color: ${(props) =>
    props.variant === "primary"
      ? janeWhite
      : props.variant === "link" && primary};
  border: none;
  background: ${(props) => props.variant === "primary" && primary};
  border-color: ${(props) => props.variant === "primary" && primary};
  padding: ${paddingBaseVertical} ${paddingBaseHorizontal};
  font-size: ${fontSizeBase};
  line-height: ${lineHeightBase};
  border-radius: ${borderRadiusBase};
  ${(props) =>
    props.xs &&
    `
    padding: ${paddingxsVertical} ${paddingxsHorizontal};
    font-size: ${fontSizeSmall};
    line-height: ${lineHeightSmall};
    border-radius: ${borderRadiusSmall};
  `};
  ${(props) =>
    props.sm &&
    `
    padding: ${paddingsmallVertical} ${paddingsmallHorizontal};
    font-size: ${fontSizeSmall};
    line-height: ${lineHeightSmall};
    border-radius: ${borderRadiusSmall};
  `};
  ${(props) =>
    props.lg &&
    `
    margin-top: 1rem;
    padding: ${paddinglargeVertical} ${paddinglargeHorizontal};
    font-size: ${fontSizeLarge};
    line-height: ${lineHeightLarge};
    border-radius: ${borderRadiusLarge};
  `};
  ${(props) =>
    props.block &&
    `
      display: block;
      width: 100%;
  `};
  ${(props) =>
    props.variant === "primary" &&
    `
      &:hover {
        background-color: ${primary};
        border-color: ${activePrimaryBorder};
        color: ${janeWhite};
      }
    `}
  ${(props) =>
    props.variant === "link" &&
    `
    font-size: ${fontSizeLink};
    font-weight: ${props.lightWeight ? "400" : "700"};
    background: white;
    &:hover {
        color: ${primary};
      }
  `}
  ${(props) =>
    props.variant === "white-link" &&
    `
    font-size: ${fontSizeLink};
    font-weight: ${props.lightWeight ? "400" : "700"};
    color: white;
    background: none;
  `}
  ${(props) =>
    props.disabled &&
    `
    color: white;
    background: #DEDEDE;
    cursor: none;
    &:hover {
      cursor: not-allowed
    }

  `}
  ${(props) =>
    props.noPadding &&
    `
    padding: 0;
  `}
`
