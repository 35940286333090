import { Button } from "../../components"

const AddClinic = ({ url }: { url: string }) => (
  <a href={url}>
    <Button variant="primary">
      <span className="text-sm">Add</span>
    </Button>
  </a>
)

export const SearchResultClinicButton = ({
  isLinked,
  addClinicUrl,
}: {
  isLinked: boolean
  addClinicUrl: string
}) => {
  return (
    <div className="flex justify-end items-end ">
      {isLinked ? (
        <Button disabled>Added</Button>
      ) : (
        <AddClinic url={addClinicUrl} />
      )}
    </div>
  )
}
