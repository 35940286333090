import * as Sentry from "@sentry/react"
import { env } from "./env"

export const initializeSentry = () => {
  Sentry.init({
    dsn: "https://b4a779c5f252631c83f2dfbae07569b4@o4505290921410560.ingest.sentry.io/4506181787385856",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [env.REACT_APP_ID_API_BASE_URL],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 10% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
