import { useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { primary } from "../../components/variables"
import { ReactComponent as JaneLogoReversed } from "../../images/JaneLogoReversed.svg"

const NavBarStyle = styled.div`
  width: 100%;
  padding: 0.625rem 0.6563rem;
  background: ${primary};
`
const JaneLogoWrapper = () => {
  return (
    <div className="col-start-2 col-end-3 flex justify-center">
      <JaneLogoReversed className="h-12 w-12" />
    </div>
  )
}
export const NavBarLogo = ({ logout }: { logout: () => void }) => {
  const [params] = useSearchParams()
  const isMobile = params.get("device") === "mobile"
  return (
    <NavBarStyle className="grid grid-cols-3">
      <JaneLogoWrapper />
      {!isMobile && (
        <button
          onClick={() => logout()}
          className="col-start-3 col-end-4 p text-white text-right pr-12 hidden lg:block"
        >
          Sign out
        </button>
      )}
    </NavBarStyle>
  )
}
