import { useOidc } from "@axa-fr/react-oidc"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext } from "react"
import { ErrorRefreshFallback } from "../../components/ErrorRefreshFallback"
import { PanelButton } from "../../components/PanelButton"
import { KeycloakContext } from "../../context/KeycloakContext"

const chevronButton = (
  <button>
    <FontAwesomeIcon icon={faChevronRight} />
  </button>
)

export const EmailPassword = () => {
  const { login } = useOidc()
  const { userCredentials, error } = useContext(KeycloakContext)
  const email = userCredentials?.email || ""
  const emailPasswordError = error?.userCredentialError
  return (
    <div>
      {emailPasswordError ? (
        <ErrorRefreshFallback />
      ) : (
        <>
          <PanelButton
            className="rounded-t p-6 flex justify-between items-center bg-white"
            title="Login Email"
            subtitle={email}
            interactiveButton={chevronButton}
            borderBottomNone
            onClick={() => login(undefined, { kc_action: "UPDATE_EMAIL" })}
          />
          <PanelButton
            className="rounded-b p-6 flex justify-between items-center bg-white"
            title="Password"
            subtitle="Last changed..."
            interactiveButton={chevronButton}
            onClick={() => login(undefined, { kc_action: "UPDATE_PASSWORD" })}
          />
        </>
      )}
    </div>
  )
}
