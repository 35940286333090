import { useOidc } from "@axa-fr/react-oidc"
import { useContext } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { NavBarLogo } from "../../components/NavBarLogo"
import { KeycloakContext } from "../../context/KeycloakContext"
export const Layout = () => {
  const { loading } = useContext(KeycloakContext)
  const { logout } = useOidc()
  const location = useLocation()
  const { pathname } = location
  // this is a temp render, until a loading screen is designed
  if (loading) return null
  const notSearchClinic = !pathname.includes("/search-clinic")
  return (
    <div className="h-full flex flex-col min-h-screen">
      {notSearchClinic && <NavBarLogo logout={logout} />}
      <div className={`grid grid-cols-12`}>
        <div className="p-[15px] col-start-1 col-end-13 md:col-start-3 md:col-end-11">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
