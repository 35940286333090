import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef } from "react"
interface SearchProps {
  handleSearchText: (text: string) => void
}
const SearchInput = ({ handleSearchText }: SearchProps) => {
  const searchInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus()
    }
  })
  return (
    <div className="flex items-center w-full mt-1">
      <FontAwesomeIcon
        className="text-greyDark pl-2 absolute"
        icon={faMagnifyingGlass}
      />
      <input
        ref={searchInputRef}
        role="input"
        className="border border-grey800 w-full outline-0 pl-8 py-2.5 focus:border-janeLightBlue jane-box-shadow h-11 text-lg rounded-md placeholder-greyDark"
        placeholder="Search for clinics, studios, etc..."
        onChange={(e) => handleSearchText(e.target.value)}
      />
    </div>
  )
}

const Search = ({ handleSearchText }: SearchProps) => {
  return (
    <div>
      <h1 className="font-bold text-2xl leading-[30px]">Find an account</h1>
      <SearchInput handleSearchText={handleSearchText} />
    </div>
  )
}

export { Search }
