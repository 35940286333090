import { faCircleCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import DefaultLogo from "../../assets/logoDefault.png"
import { ClinicType } from "../../interfaces/LoginSecurity"

interface CinicPanelMobileProps {
  className?: string
  addClinicUrl: string
  clinic: ClinicType
  title: string
  subtitle: string
  logo: string
}

interface ImageErrorEvent
  extends React.SyntheticEvent<HTMLImageElement, Event> {
  target: HTMLImageElement
}

export const ClinicPanelMobile = ({
  className,
  addClinicUrl,
  clinic,
  title,
  subtitle,
  logo,
}: CinicPanelMobileProps) => {
  const linkExists = clinic?.linked
  return (
    <div
      data-testid="clinic-result"
      className={`${
        linkExists && "pointer-events-none"
      } rounded-md p-4  border border-greyLighter hover:border-janeBlue active:border-janeBlue focus:border-janeBlue flex flex-col ${className}`}
    >
      <a href={addClinicUrl}>
        <div className="flex items-center">
          {logo && (
            <div className="flex-shrink-0 h-[40px] w-[40px] mr-2 border-[1px] border-greyLighter rounded flex justify-center items-center">
              <img
                className="rounded object-cover"
                src={logo}
                alt={`${title} logo`}
                onError={(e: ImageErrorEvent) => {
                  e.target.src = DefaultLogo
                }}
              />
            </div>
          )}

          <div className="flex flex-col">
            <div className="font-bold	text-base leading-[125%]">{title}</div>
            <div className="font-light text-sm">{subtitle}</div>
          </div>
        </div>
        {linkExists && (
          <div className="flex justify-end items-center mt-1">
            <FontAwesomeIcon
              className="text-successGreen pl-2 pr-1"
              icon={faCircleCheck}
            />
            <div className="font-bold	text-sm">Added</div>
          </div>
        )}
      </a>
    </div>
  )
}
