import { env } from "./env"

export const configuration = {
  client_id: "jane_id_dashboard",
  redirect_uri: `${env.REACT_APP_DASHBOARD_BASE_URL}/authenticate`,
  scope: "openid",
  authority: env.REACT_APP_KEYCLOAK_BASE_URL,
  service_worker_relative_url: "/OidcServiceWorker.js",
  service_worker_only: true,
  refresh_time_before_tokens_expiration_in_second: 40,
}
