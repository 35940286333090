import styled from 'styled-components'
import {
  janeDark,
  primary,
  regularFontFamily,
  thinFontFamily,
} from './variables'

interface StatementStyleProps {
  align?: 'left' | 'center'
}

export const StatementStyle = styled.div<StatementStyleProps>`
  font-family: ${regularFontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${janeDark};
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  text-align: ${(props) => (props.align === 'left' ? 'left' : 'center')};
`
export const JaneImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 20%;
  }
`
export const LoginLink = styled.a`
  font-size: 16px;
  font-weight: 400;
  color: ${primary};
  font-family: ${thinFontFamily};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${primary};
  }
`

export const BoldTextStyle = styled.div`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  text-align: center;
`

export const NormalTextStyle = styled.div`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  text-align: center;
`

export const FormInput = styled.input`
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`

export const SubmitText = styled.span`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 14px;
`

interface FlexBoxProps {
  marginTop?: boolean
  spaceBetween?: boolean
  flexEnd?: boolean
  center?: boolean
  children?: React.ReactNode
  alignItems?: string
}

export const FlexBox = styled.div<FlexBoxProps>`
  display: flex;
  margin-top: ${(props) => props.marginTop && '1rem'};
  justify-content: ${(props) =>
    props.spaceBetween
      ? 'space-between'
      : props.flexEnd
      ? 'flex-end'
      : props.center
      ? 'center'
      : 'flex-start'};
  align-items: ${(props) => props.alignItems};
`
