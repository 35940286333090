import styled from "styled-components"

const PanelText = ({
  title,
  subtitle,
  iconSubtitle,
  className,
  titleBold,
}: any) => {
  return (
    <div className={className}>
      <div
        className={`text-janeBlack ${
          titleBold ? "font-semibold" : "font-medium"
        }`}
      >
        {title}
      </div>
      <div className="flex flex-row items-center ">
        {iconSubtitle && <div className="mr-1">{iconSubtitle}</div>}
        <div className="text-sm text-greyDark">{subtitle}</div>
      </div>
    </div>
  )
}

interface PanelWrapperProps {
  borderBottomNone?: boolean
}

const PanelWrapper = styled.div<PanelWrapperProps>`
  border-width: 1px;
  border-style: solid;
  border-color: #dddddd;
  ${(props) =>
    props.borderBottomNone &&
    `
      border-bottom: 0px;
    `}
`

interface ImageErrorEvent
  extends React.SyntheticEvent<HTMLImageElement, Event> {
  target: HTMLImageElement
}

export const PanelButton = (props: any) => {
  const {
    title,
    subtitle,
    borderBottomNone,
    interactiveButton,
    iconSubtitle,
    imgSrc,
    fallbackImage,
    titleBold,
  } = props
  return (
    <PanelWrapper role="group" borderBottomNone={borderBottomNone} {...props}>
      {imgSrc ? (
        <div className="flex items-center gap-2">
          <img
            className="rounded-md border border-solid border-greyLighter w-10 h-10 object-contain"
            alt={`${title} logo`}
            src={imgSrc}
            onError={(e: ImageErrorEvent) => {
              e.target.src = fallbackImage
            }}
          />
          <PanelText
            className="inline-block"
            titleBold={titleBold}
            title={title}
            subtitle={subtitle}
            iconSubtitle={iconSubtitle}
          />
        </div>
      ) : (
        <PanelText
          title={title}
          titleBold={titleBold}
          subtitle={subtitle}
          iconSubtitle={iconSubtitle}
        />
      )}
      {interactiveButton}
    </PanelWrapper>
  )
}
