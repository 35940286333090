import styled from 'styled-components'

interface IconStyleProps {
  paddingLeft?: string
  paddingRight?: string
  height?: string
  width?: string
}

export const IconStyle = styled.span<IconStyleProps>`
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '0px')};
  padding-right: ${(props) =>
    props.paddingRight ? props.paddingRight : '0px'};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`
