import { ErrorRefreshFallback } from "../../components/ErrorRefreshFallback"
import ErrorBoundary from "../ErrorBoundary"
import { Devices } from "./Devices"
import { EmailPassword } from "./EmailPassword"

export const LoginSecurity = () => {
  return (
    <div className="py-6">
      <h1 className="font-bold mb-4">Email & Password</h1>
      <ErrorBoundary fallback={<ErrorRefreshFallback />}>
        <EmailPassword />
      </ErrorBoundary>
      <ErrorBoundary
        fallback={<ErrorRefreshFallback />}
        header={<h1 className="font-bold pt-6 mb-4">Where you're signed in</h1>}
      >
        <Devices />
      </ErrorBoundary>
    </div>
  )
}
