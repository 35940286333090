import { useOidc } from "@axa-fr/react-oidc"

export const ErrorRefreshFallback = () => {
  const { logout } = useOidc()
  return (
    <div className="flex flex-wrap gap-1 text-janeBlack border border-greyLighter rounded p-6 mt-5">
      <span>Oops! Something went wrong.</span>
      <span>
        <a href="#" className="text-janeBlue" onClick={() => logout()}>
          Refresh page
        </a>
      </span>
    </div>
  )
}
