import { useOidc } from "@axa-fr/react-oidc"
import { useContext } from "react"
import { KeycloakContext } from "../../context/KeycloakContext"
import { DeleteAllSessions } from "../../helpers/api"
import { DevicesList } from "./DevicesList"

export const Devices = () => {
  const { connectedDevices, error } = useContext(KeycloakContext)

  const { logout } = useOidc()

  const devicesError = error?.connectedDevicesError
  const signOutAllSessions = async () => {
    const response = await DeleteAllSessions()
    if (response && response.ok) {
      logout()
    }
  }
  return (
    <div className="pt-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold">Where you're signed in</h1>
        {!devicesError && (
          <button
            className="bg-janeRed text-white text-sm p-[10px] rounded border border-greyLighter"
            onClick={() => signOutAllSessions()}
          >
            Sign Out From All
          </button>
        )}
      </div>
      <DevicesList
        connectedDevices={connectedDevices}
        hasError={devicesError}
      />
    </div>
  )
}
