import moment from "moment"
import { useContext } from "react"
import styled from "styled-components"
import { ErrorRefreshFallback } from "../../components/ErrorRefreshFallback"
import { Panel } from "../../components/Panel"
import { PanelButton } from "../../components/PanelButton"
import { KeycloakContext } from "../../context/KeycloakContext"
import { KeycloakContextType } from "../../interfaces/LoginSecurity"

export const DevicesList = ({
  connectedDevices,
  hasError,
}: {
  connectedDevices: KeycloakContextType["connectedDevices"]
  hasError?: boolean
}) => {
  const { signOutSessionByDeviceId } = useContext(KeycloakContext)
  const signOutHandler = async (deviceID: string, idx: number) => {
    await signOutSessionByDeviceId(deviceID, idx)
  }

  const SignOutButtonStyle = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
  `

  const SignOutButton = (deviceID: string, idx: number) => {
    return (
      <SignOutButtonStyle onClick={() => signOutHandler(deviceID, idx)}>
        Sign Out
      </SignOutButtonStyle>
    )
  }
  if (connectedDevices.length === 1) {
    const singleDevice = connectedDevices[0]
    const isCurrent = singleDevice.current
    const epochLastAccess = Number(singleDevice.lastAccess)
    const lastAccess = moment.unix(epochLastAccess).startOf("hour").fromNow()
    return (
      <>
        {hasError ? (
          <ErrorRefreshFallback />
        ) : (
          <PanelButton
            className="rounded p-6 flex justify-between items-center bg-white"
            title={singleDevice.os}
            iconSubtitle={singleDevice.browser}
            subtitle={isCurrent ? "· Active Now" : lastAccess}
            interactiveButton={!isCurrent ? SignOutButton : null}
          />
        )}
      </>
    )
  }

  const makeSessions = () => {
    return connectedDevices.map((device, idx) => {
      const isCurrent = device.current
      const epochLastAccess = Number(device.lastAccess)
      const lastAccess = moment.unix(epochLastAccess).startOf("hour").fromNow()
      const isFirstItem = idx === 0

      // Items that are neither first or last should get no rounded borders.
      let rounded
      if (isFirstItem) {
        rounded = "rounded-t"
      } else {
        rounded = ""
      }

      // For the key, we need the backend to generate a GUID -- this isn't ideal.
      return (
        <PanelButton
          className={`${rounded} p-6 flex justify-between items-center bg-white`}
          key={idx}
          title={device.os}
          iconSubtitle={device.browser}
          subtitle={isCurrent ? "· Active Now" : lastAccess}
          interactiveButton={!isCurrent ? SignOutButton(device.id, idx) : null}
          borderBottomNone={true}
        />
      )
    })
  }

  return (
    <>
      {hasError ? (
        <ErrorRefreshFallback />
      ) : (
        <>
          {makeSessions()}
          <Panel
            className="flex justify-center"
            marginTopNone={true}
            removeBorderRadius="top"
          >
            <SignOutButtonStyle>Expand</SignOutButtonStyle>
          </Panel>
        </>
      )}
    </>
  )
}
