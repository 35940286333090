import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const GenericErrorPage = () => {
  return (
    <div className="h-full min-h-screen grid grid-cols-12">
      <div className="flex flex-col justify-center col-start-2 col-end-12 md:col-start-4 md:col-end-10">
        <div className="flex flex-col md:border md:border-greyLighter md:p-10 md:rounded">
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className="text-janeBlue h-10 pb-4"
          />
          <h1 className="text-3xl font-light text-center">
            Something went wrong
          </h1>
          <p className="mt-2 text-greyDark text-center ">
            Oops, there was a problem. Please try again
          </p>
          <button
            className="p-4 mt-4 text-white bg-janeBlue rounded text-lg "
            onClick={() => window.location.reload()}
          >
            Try Again
          </button>
        </div>
      </div>
    </div>
  )
}
